import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';
import 'firebase/compat/app-check';
import 'firebase/compat/analytics';
import { getMultiFactorResolver as firebaseGetMultiFactorResolver } from 'firebase/auth';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { intercomInit } from '../variableModules/intercom.module';
import { appcuesInit } from '../variableModules/appcues.module';
import { signInWithEmailAndPassword } from '../variableModules/auth.module';
import { tosCheckFunction } from '../variableModules/tosCheck.module';
import * as coreui from '@coreui/coreui-pro';
import Swal from 'sweetalert2';
import versionJSON from './version.json';
import ComponentLoader from '../variableModules/ComponentLoader';

// Define Required Variables for Initialization
var firebaseConfig = {};
var sentryEnabled = true;
var appCheck = null;
var recaptchaKey = '';
var app = null;
var auth = null;
var analytics = null;
var db = null;
var firebaseAuth = null;
var functions = null;
var functionsUS = null;
var storage = null;
var storageRef = null;
var FirebaseFieldPath = null;
var FirebaseFieldValue = null;
var FirebaseTimestamp = null;
var publicKey = '';

try { // Initialize Firebase and other required variables
  window.ComponentLoader = new ComponentLoader();
  window.coreui = coreui;
  window.Swal = Swal;
  firebaseConfig = {
    apiKey: `${import.meta.env.VITE_apiKey}`,
    authDomain: `${import.meta.env.VITE_authDomain}`,
    projectId: `${import.meta.env.VITE_projectId}`,
    storageBucket: `${import.meta.env.VITE_storageBucket}`,
    messagingSenderId: `${import.meta.env.VITE_messagingSenderId}`,
    appId: `${import.meta.env.VITE_appId}`,
    measurementId: `${import.meta.env.VITE_measurementId}`,
  };
  console.log(firebaseConfig);
  if (import.meta.env.MODE === 'development') {
    sentryEnabled = false;
  }
  firebase.initializeApp(firebaseConfig);
  appCheck = firebase.appCheck();
  recaptchaKey = import.meta.env.VITE_recaptchaKey;
  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('127.0.0.1') ||
    window.location.host.includes('.local')
  ) {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = "f5d1c1b0-d9ac-492e-989c-86a4771ca959";
  }
  app = firebase.app();
  auth = firebase.auth(app);
  analytics = firebase.analytics(app);
  db = firebase.firestore(app);
  firebaseAuth = firebase.auth;
  functions = app.functions('australia-southeast1');
  functionsUS = app.functions('us-central1');
  storage = firebase.storage(app);
  storageRef = storage.ref();
  FirebaseFieldPath = firebase.firestore.FieldPath;
  FirebaseFieldValue = firebase.firestore.FieldValue;
  FirebaseTimestamp = firebase.firestore.Timestamp;
  publicKey = import.meta.env.VITE_publicKey;
  window.cloudFunctionURL = (window.location.hostname.includes('localhost') || window.location.hostname.includes("127.0.0.1"))
    ? 'http://localhost:5001/dev-deiterate/australia-southeast1'
    : import.meta.env.VITE_cloudFunctionURL;
  window.cloudFunctionURL = window.location.hostname.includes('127.0.0.1')
    ? 'http://localhost:5001/dev-deiterate/australia-southeast1'
    : import.meta.env.VITE_cloudFunctionURL;
  window.cloudFunctionURL = window.location.hostname.includes('.local')
    ? 'http://localhost:5001/dev-deiterate/australia-southeast1'
    : import.meta.env.VITE_cloudFunctionURL;
  if (
    window.location.host.includes('localhost') ||
    window.location.host.includes('127.0.0.1') ||
    window.location.host.includes('.local')
  ) {
    functions.useEmulator('localhost', 5001);
    appCheck.activate(new firebase.appCheck.ReCaptchaEnterpriseProvider(recaptchaKey), false);
  } else {
    appCheck.activate(new firebase.appCheck.ReCaptchaEnterpriseProvider(recaptchaKey), true);
  }
  Sentry.init({
    dsn: sentryEnabled === false ? 'https://5b746c4b18d3d62bbd42c963754fc310@o4506330372702208.ingest.us.sentry.io/4506334421516288' : "https://bf894d60308b3ec204aee45b8c837de3@o4506330372702208.ingest.us.sentry.io/4506697576087552",
    release: `deiterate@${versionJSON.version}`,
    integrations: [Sentry.browserTracingIntegration, Sentry.replayIntegration],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      /^https:\/\/admin\.deiterate\.com/,
      /^https:\/\/login\.deiterate\.com/,
    ],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  });
  window.global ||= window;
  window.signInWithEmailAndPassword = signInWithEmailAndPassword;
  appcuesInit();
  intercomInit();
  tosCheckFunction();
} catch (error) {
  console.error('Error during initialization:', error);
  if (Swal) {
    Swal.fire({
      title: 'Error during initialization, please refresh the page.',
      text: error,
      icon: 'error',
      confirmButtonText: 'Okay',
    });
  }
}

function injectBanner() {
  // const banner = document.createElement('div');
  // banner.id = 'dynamic-banner';

  // const style = document.createElement('style');
  // style.innerHTML = `
  //     #dynamic-banner {
  //         display: none; /* Hidden by default */
  //         // position: fixed;
  //         // top: 0;
  //         // left: 0;
  //         width: 100%;
  //         background-color: yellow;
  //         color: black;
  //         text-align: center;
  //         padding: 10px;
  //         z-index: 1000;
  //     }
  // `;

  // document.head.appendChild(style);
  // document.body.prepend(banner);

  function showBanner() {
    const isSmallScreen = window.innerWidth <= 1137; // Adjust the width as needed
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    const bannerShown = sessionStorage.getItem('bannerShown');
    const path = window.location.pathname;

    // Debugging output
    console.log('Banner shown:', bannerShown);

    if (bannerShown === null) {
      let message = '';
      if (isSmallScreen && isFirefox && path !== '/account_management') {
        message = 'de.iterate may not function as expected on small screens in Firefox.';
      } else if (isSmallScreen && path !== '/account_management') {
        message = 'de.iterate may not function as expected on small screens.';
      } else if (isFirefox) {
        message = 'de.iterate may not function as expected in Firefox.';
      }

      if (message) {
        Swal.fire({
          title: 'Warning',
          text: message,
          icon: 'warning',
          confirmButtonText: 'Okay',
        }).then(() => {
          // Store that the banner has been shown
          sessionStorage.setItem('bannerShown', 'true');
        });
      }
    }
  }

  // Run the function on page load and on window resize
  window.addEventListener('load', showBanner);
  window.addEventListener('resize', showBanner);
}

auth.onAuthStateChanged(async function (user) {
  if (user) {
    window.__DebugUser = user;
    const token = await user.getIdToken();
    const uid = user.uid;
    document.cookie = `__session=${token}--__--${uid}`;
  }
});

window.addEventListener('DOMContentLoaded', injectBanner);

export {
  firebaseConfig,
  app,
  auth,
  analytics,
  db,
  firebase as firebaseImport,
  functions,
  storage,
  storageRef,
  FirebaseFieldValue,
  FirebaseTimestamp,
  FirebaseFieldPath,
  firebaseAuth,
  moment,
  publicKey,
  functionsUS,
  firebaseGetMultiFactorResolver,
};
